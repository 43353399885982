import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectionDirective } from './direction/direction.directive';
import { ShowInAppDirective } from './show-in-app/show-in-app.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ShowInAppDirective, DirectionDirective],
  exports: [ShowInAppDirective, DirectionDirective],
})
export class BuilderModule {}
