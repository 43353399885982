// tslint:disable-next-line:nx-enforce-module-boundaries
import { PromocodePaginationRequest } from '../../../../models/promocodePaginationRequest';
import { AppType, BuilderEnvironment, LanguageLocale, Theme } from '../builder.interface';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { SkuItemShared } from '../../../../models/skuItemShared';
import CurrencyEnum = SkuItemShared.CurrencyEnum;
import LangEnum = PromocodePaginationRequest.LangEnum;

type AvailableBuilderEnv = Record<
  AppType,
  {
    test: BuilderEnvironment;
    production: BuilderEnvironment;
  }
>;

type BaseBuilderEnv = Record<AppType, BuilderEnvironment>;

const baseLogosPath = 'assets/images/logos/';

export const BaseBuilderEnvs: BaseBuilderEnv = {
  [AppType.Gdesemena]: {
    appType: AppType.Gdesemena,
    currency: CurrencyEnum.RUB,
    locale: LanguageLocale.ru,
    currencySymbol: '₽',
    currencySettings: [
      {
        active: true,
        conversionRatio: 1,
        format: 'ru',
        currencySymbol: '₽',
        currency: CurrencyEnum.RUB,
      },
    ],
    country: 'RUS',
    timeOut: 50000,
    systemLang: LangEnum.RU,
    phoneSettings: [{ active: true, phoneLength: 10, phonePrefix: 7 }],
    langSetting: {
      defaultLang: LangEnum.RU,
      availableLangs: [LangEnum.RU, LangEnum.EN],
    },
    assets: {
      logo: {
        sidebar: {
          normal: baseLogosPath + 'default/logo.svg',
          light: baseLogosPath + 'default/logo-light.svg',
          small: baseLogosPath + 'default/logo-small.svg',
        },
        authPage: {
          normal: baseLogosPath + 'default/logo.svg',
        },
      },
    },
  },
  [AppType.Rshb]: {
    appType: AppType.Rshb,
    currency: CurrencyEnum.RUB,
    locale: LanguageLocale.ru,
    currencySymbol: '₽',
    currencySettings: [
      {
        active: true,
        conversionRatio: 1,
        format: 'ru',
        currencySymbol: '₽',
        currency: CurrencyEnum.RUB,
      },
    ],
    country: 'Russia',
    timeOut: 50000,
    systemLang: LangEnum.RU,
    phoneSettings: [{ active: true, phoneLength: 10, phonePrefix: 7 }],
    langSetting: {
      defaultLang: LangEnum.RU,
      availableLangs: [LangEnum.RU, LangEnum.EN],
    },
    assets: {
      logo: {
        sidebar: {
          normal: baseLogosPath + 'default/logo.svg',
          light: baseLogosPath + 'default/logo-light.svg',
          small: baseLogosPath + 'default/logo-small.svg',
        },
        authPage: {
          normal: baseLogosPath + 'default/logo.svg',
        },
      },
    },
  },
  [AppType.Safal]: {
    appType: AppType.Safal,
    currency: CurrencyEnum.INR,
    locale: LanguageLocale.ru,
    currencySymbol: '₹',
    currencySettings: [
      {
        active: true,
        conversionRatio: 1,
        format: 'ru',
        currencySymbol: '₹',
        currency: CurrencyEnum.INR,
      },
    ],
    country: 'India',
    timeOut: 50000,
    systemLang: LangEnum.EN,
    phoneSettings: [{ active: true, phoneLength: 10, phonePrefix: 91 }],
    langSetting: {
      defaultLang: LangEnum.RU,
      availableLangs: [LangEnum.HI, LangEnum.EN],
    },
    assets: {
      logo: {
        sidebar: {
          normal: baseLogosPath + 'default/logo.svg',
          light: baseLogosPath + 'default/logo-light.svg',
          small: baseLogosPath + 'default/logo-small.svg',
        },
        authPage: {
          normal: baseLogosPath + 'safal/logo.svg',
        },
      },
    },
  },
  [AppType.Stc]: {
    appType: AppType.Stc,
    currency: CurrencyEnum.USD,
    locale: LanguageLocale.en,
    currencySymbol: '$',
    currencySettings: [
      {
        active: true,
        conversionRatio: 1,
        format: 'en',
        currencySymbol: '$',
        currency: CurrencyEnum.USD,
      },
    ],
    country: 'Saudi Arabia',
    timeOut: 50000,
    systemLang: LangEnum.EN,
    phoneSettings: [{ active: true, phoneLength: 10, phonePrefix: 7 }],
    langSetting: {
      defaultLang: LangEnum.RU,
      availableLangs: [LangEnum.EN],
    },
    assets: {
      logo: {
        sidebar: {
          normal: baseLogosPath + 'stc/logo.svg',
          light: baseLogosPath + 'stc/logo-light.svg',
          small: baseLogosPath + 'stc/logo-small.svg',
        },
        authPage: {
          normal: baseLogosPath + 'stc/logo.svg',
        },
      },
    },
    theme: Theme.STC,
  },
};

export const AvailableBuildEnvironments: AvailableBuilderEnv = {
  [AppType.Rshb]: {
    test: {
      ...BaseBuilderEnvs[AppType.Rshb],
      recaptcha: '6LfRSyIUAAAAAHZyC_5pyjkyUMDxyWTRw32s8nlk',
    },
    production: {
      ...BaseBuilderEnvs[AppType.Rshb],
      recaptcha: '6Lei2YQUAAAAAOPEovb74Un5cuzmuNVQ7yWta4Nn',
    },
  },
  [AppType.Gdesemena]: {
    test: {
      ...BaseBuilderEnvs[AppType.Gdesemena],
      recaptcha: '6LfRSyIUAAAAAHZyC_5pyjkyUMDxyWTRw32s8nlk',
    },
    production: {
      ...BaseBuilderEnvs[AppType.Gdesemena],
      recaptcha: '6Lei2YQUAAAAAOPEovb74Un5cuzmuNVQ7yWta4Nn',
    },
  },
  [AppType.Safal]: {
    test: {
      ...BaseBuilderEnvs[AppType.Safal],
      recaptcha: '6LfRSyIUAAAAAHZyC_5pyjkyUMDxyWTRw32s8nlk',
    },
    production: {
      ...BaseBuilderEnvs[AppType.Safal],
      recaptcha: '6Lepx8YUAAAAAJYmHatsTWFjOkonNcr2t2-cCCsC',
    },
  },
  [AppType.Stc]: {
    test: {
      ...BaseBuilderEnvs[AppType.Stc],
      recaptcha: '6LfRSyIUAAAAAHZyC_5pyjkyUMDxyWTRw32s8nlk',
    },
    production: {
      ...BaseBuilderEnvs[AppType.Stc],
      recaptcha: '6Lepx8YUAAAAAJYmHatsTWFjOkonNcr2t2-cCCsC',
    },
  },
};
