export const companiesClientContext = {
  delete: '',
  update: '/user-service/api/external/companies/{id}',
  add: '/user-service/api/external/companies',
  getAll: '/user-service/api/external/companies',
  get: '/user-service/api/external/companies/{id}/details',
  updateLogo: '/user-service/api/external/companies/{id}/logo',
  register: '/user-service/api/external/companies/registartion',
  fieldsStructure: '/user-service/api/external/fieldsStructure',
  updateHTML: '/user-service/api/external/companies/{id}/htmlContent',
  companyMembers: '/user-service/api/external/companies/{id}/members',
  self: {
    updateHtml: '/user-service/api/external/companies/self/htmlContent',
    updateImages: '/user-service/api/external/companies/self/images',
    updateLogo: '/user-service/api/external/companies/self/logo',
  },
  image: {
    uploadImage: '/user-service/api/external/companies/{id}/image',
    updateImage: '/user-service/api/external/companies/{id}/image/{imageId}',
    reorganizeImage: '/user-service/api/external/companies/{id}/image/{imageId}/order',
    deleteImage: '/user-service/api/external/companies/{id}/image/{imageId}',
  },
  seller: {
    create: '/user-service/api/external/legalSellers',
    memberById: '/user-service/api/external/legalSellers/{id}/member/{memberId}',
    memberStatus: '/user-service/api/external/legalSellers/{id}/member/{memberId}/status',
    createMember: '/user-service/api/external/legalSellers/{id}/member',
    createMemberFromUser: '/user-service/api/external/legalSellers/{id}/member/fromUser',
    statistics: '/web-api/sellerOrders/{id}/statistics',
    status: '/user-service/api/external/legalSellers/{id}/status',
    update: '/user-service/api/external/legalSellers/{id}',
  },
  buyer: {
    create: '/user-service/api/external/legalBuyers',
    getById: '/user-service/api/external/legalBuyers/{id}',
    memberById: '/user-service/api/external/legalBuyers/{id}/member/{memberId}',
    memberStatus: '/user-service/api/external/legalBuyers/{id}/member/{memberId}/status',
    createMember: '/user-service/api/external/legalBuyers/{id}/member',
    createMemberFromUser: '/user-service/api/external/legalBuyers/{id}/member/fromUser',
    status: '/user-service/api/external/legalBuyers/{id}/status',
  },
  agents: {
    create: '/user-service/api/external/legalAgents',
    getById: '/user-service/api/external/legalAgents/{id}',
    memberById: '/user-service/api/external/legalAgents/{id}/member/{memberId}',
    memberStatus: '/user-service/api/external/legalAgents/{id}/member/{memberId}/status',
    createMember: '/user-service/api/external/legalAgents/{id}/member',
    createMemberFromUser: '/user-service/api/external/legalAgents/{id}/member/fromUser',
    status: '/user-service/api/external/legalAgents/{id}/status',
  },
  channels: {
    getAll: '/user-service/api/external/alternateChannels',
    add: '/user-service/api/external/alternateChannels',
    getById: '/user-service/api/external/alternateChannels/{id}',
    update: '/user-service/api/external/alternateChannels/{id}',
    remove: '/user-service/api/external/alternateChannels/{id}',
  },
};
