export const ordersClientContext = {
  addItem: '/order-service/rest/api/external/orders/{id}/addItem',
  count: '/order-service/rest/api/external/orders/{id}/changeCount',
  comment: '/order-service/rest/api/external/orders/{id}/comment',
  getByPage: '/order-service/rest/api/external/orders',
  get: '/order-service/rest/api/external/orders/',
  invoice: '/order-service/rest/api/external/orders/{id}/invoice',
  fieldsStructure: '/order-service/api/external/fieldsStructure',
  additionalFields: '/order-service/rest/api/external/orders/{id}/additionalFields',
  performActionActive: '/order-service/rest/api/external/orders/{id}/performAction',
  statistics: '/web-api/sellerOrders/self/statistics',
  getOperatorOrders: '/web-api/orders',
  downloadOrdersReport: '/web-api/orders/report',
  metaship: '/logistics-service/api/external/orders/{id}/label',
  changePeriod: '/order-service/rest/api/external/orders/{id}/period',
  downloadSalesReport: '/web-api/orders/report/sales',
  receiveChallan: '/order-service/rest/api/external/orders/{id}/challan',
  changeTotal: '/order-service/rest/api/external/orders/{id}/changeTotal',
  invoiceTemplates: '/order-service/rest/api/external/invoiceTemplates',
  invoiceTemplate: '/order-service/rest/api/external/invoiceTemplates/{id}',
  countBadge: '/order-service/rest/api/external/orders/count',
};
