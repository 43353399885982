/**
 * User service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Request contains user role name and privileges
 */
export interface RoleSaveRequest { 
    roleName: string;
    description?: string;
    privileges: Array<RoleSaveRequest.PrivilegesEnum>;
    profileType: RoleSaveRequest.ProfileTypeEnum;
}
export namespace RoleSaveRequest {
    export type PrivilegesEnum = 'MERCHANT_PROFILE_WRITE' | 'MERCHANT_PROFILE_READ' | 'COMPANIES_WRITE' | 'COMPANIES_READ' | 'MEMBERS_WRITE' | 'MEMBERS_READ' | 'ORDER_READ' | 'ORDER_WRITE' | 'CLIENTS_WRITE' | 'CLIENTS_READ' | 'ITEMS_READ' | 'ITEMS_WRITE' | 'REQUESTS_READ' | 'REQUESTS_WRITE' | 'PAGES_WRITE' | 'PAGES_READ' | 'ARTICLES_WRITE' | 'ARTICLES_READ' | 'LOGS_REPORT_READ' | 'DASHBOARD_READ' | 'USER_PROFILE_READ' | 'USER_PROFILE_WRITE' | 'FIN_PRODUCTS_READ' | 'FIN_PRODUCTS_WRITE' | 'ORDER_RETURNS_READ' | 'ORDER_RETURNS_WRITE' | 'AUDIT_USERS_READ' | 'AUDIT_OPERATORS_READ' | 'OPERATORS_READ' | 'OPERATORS_WRITE' | 'ROLES_READ' | 'ROLES_WRITE' | 'TEMPLATES_READ' | 'TEMPLATE_WRITE' | 'BLOCKS_READ' | 'BLOCKS_WRITE' | 'MEDIA_READ' | 'MEDIA_WRITE' | 'REVIEWS_READ' | 'REVIEWS_WRITE' | 'REVIEW_TEMPLATES_READ' | 'REVIEW_TEMPLATES_WRITE' | 'MARKETING_COMPANIES_READ' | 'MARKETING_COMPANIES_WRITE' | 'PROMOCODES_READ' | 'PROMOCODES_WRITE' | 'PAYMENT_PLANS_READ' | 'PAYMENT_PLANS_WRITE' | 'PARTNER_PRODUCT_READ' | 'PARTNER_PRODUCT_WRITE' | 'NEGOTIATIONS_READ' | 'NEGOTIATIONS_WRITE' | 'TARGET_NOTIFICATIONS_READ' | 'TARGET_NOTIFICATIONS_WRITE' | 'ORDER_SUM_WRITE' | 'BRANCHES_READ' | 'BRANCHES_WRITE' | 'DISPUTES_READ' | 'DISPUTES_WRITE' | 'TAGS_READ' | 'TAGS_WRITE' | 'CATEGORY_COMMISSION_READ' | 'CATEGORY_COMMISSION_WRITE' | 'MERCHANT_COMPANY_WRITE' | 'CATEGORY_READ' | 'CATEGORY_WRITE' | 'PAGE_SEO_TAGS_READ' | 'PAGE_SEO_TAGS_WRITE' | 'SOURCING_OUTPUTS_READ' | 'SOURCING_OUTPUTS_WRITE' | 'SOURCING_INPUTS_READ' | 'SOURCING_INPUTS_WRITE';
    export const PrivilegesEnum = {
        MERCHANTPROFILEWRITE: 'MERCHANT_PROFILE_WRITE' as PrivilegesEnum,
        MERCHANTPROFILEREAD: 'MERCHANT_PROFILE_READ' as PrivilegesEnum,
        COMPANIESWRITE: 'COMPANIES_WRITE' as PrivilegesEnum,
        COMPANIESREAD: 'COMPANIES_READ' as PrivilegesEnum,
        MEMBERSWRITE: 'MEMBERS_WRITE' as PrivilegesEnum,
        MEMBERSREAD: 'MEMBERS_READ' as PrivilegesEnum,
        ORDERREAD: 'ORDER_READ' as PrivilegesEnum,
        ORDERWRITE: 'ORDER_WRITE' as PrivilegesEnum,
        CLIENTSWRITE: 'CLIENTS_WRITE' as PrivilegesEnum,
        CLIENTSREAD: 'CLIENTS_READ' as PrivilegesEnum,
        ITEMSREAD: 'ITEMS_READ' as PrivilegesEnum,
        ITEMSWRITE: 'ITEMS_WRITE' as PrivilegesEnum,
        REQUESTSREAD: 'REQUESTS_READ' as PrivilegesEnum,
        REQUESTSWRITE: 'REQUESTS_WRITE' as PrivilegesEnum,
        PAGESWRITE: 'PAGES_WRITE' as PrivilegesEnum,
        PAGESREAD: 'PAGES_READ' as PrivilegesEnum,
        ARTICLESWRITE: 'ARTICLES_WRITE' as PrivilegesEnum,
        ARTICLESREAD: 'ARTICLES_READ' as PrivilegesEnum,
        LOGSREPORTREAD: 'LOGS_REPORT_READ' as PrivilegesEnum,
        DASHBOARDREAD: 'DASHBOARD_READ' as PrivilegesEnum,
        USERPROFILEREAD: 'USER_PROFILE_READ' as PrivilegesEnum,
        USERPROFILEWRITE: 'USER_PROFILE_WRITE' as PrivilegesEnum,
        FINPRODUCTSREAD: 'FIN_PRODUCTS_READ' as PrivilegesEnum,
        FINPRODUCTSWRITE: 'FIN_PRODUCTS_WRITE' as PrivilegesEnum,
        ORDERRETURNSREAD: 'ORDER_RETURNS_READ' as PrivilegesEnum,
        ORDERRETURNSWRITE: 'ORDER_RETURNS_WRITE' as PrivilegesEnum,
        AUDITUSERSREAD: 'AUDIT_USERS_READ' as PrivilegesEnum,
        AUDITOPERATORSREAD: 'AUDIT_OPERATORS_READ' as PrivilegesEnum,
        OPERATORSREAD: 'OPERATORS_READ' as PrivilegesEnum,
        OPERATORSWRITE: 'OPERATORS_WRITE' as PrivilegesEnum,
        ROLESREAD: 'ROLES_READ' as PrivilegesEnum,
        ROLESWRITE: 'ROLES_WRITE' as PrivilegesEnum,
        TEMPLATESREAD: 'TEMPLATES_READ' as PrivilegesEnum,
        TEMPLATEWRITE: 'TEMPLATE_WRITE' as PrivilegesEnum,
        BLOCKSREAD: 'BLOCKS_READ' as PrivilegesEnum,
        BLOCKSWRITE: 'BLOCKS_WRITE' as PrivilegesEnum,
        MEDIAREAD: 'MEDIA_READ' as PrivilegesEnum,
        MEDIAWRITE: 'MEDIA_WRITE' as PrivilegesEnum,
        REVIEWSREAD: 'REVIEWS_READ' as PrivilegesEnum,
        REVIEWSWRITE: 'REVIEWS_WRITE' as PrivilegesEnum,
        REVIEWTEMPLATESREAD: 'REVIEW_TEMPLATES_READ' as PrivilegesEnum,
        REVIEWTEMPLATESWRITE: 'REVIEW_TEMPLATES_WRITE' as PrivilegesEnum,
        MARKETINGCOMPANIESREAD: 'MARKETING_COMPANIES_READ' as PrivilegesEnum,
        MARKETINGCOMPANIESWRITE: 'MARKETING_COMPANIES_WRITE' as PrivilegesEnum,
        PROMOCODESREAD: 'PROMOCODES_READ' as PrivilegesEnum,
        PROMOCODESWRITE: 'PROMOCODES_WRITE' as PrivilegesEnum,
        PAYMENTPLANSREAD: 'PAYMENT_PLANS_READ' as PrivilegesEnum,
        PAYMENTPLANSWRITE: 'PAYMENT_PLANS_WRITE' as PrivilegesEnum,
        PARTNERPRODUCTREAD: 'PARTNER_PRODUCT_READ' as PrivilegesEnum,
        PARTNERPRODUCTWRITE: 'PARTNER_PRODUCT_WRITE' as PrivilegesEnum,
        NEGOTIATIONSREAD: 'NEGOTIATIONS_READ' as PrivilegesEnum,
        NEGOTIATIONSWRITE: 'NEGOTIATIONS_WRITE' as PrivilegesEnum,
        TARGETNOTIFICATIONSREAD: 'TARGET_NOTIFICATIONS_READ' as PrivilegesEnum,
        TARGETNOTIFICATIONSWRITE: 'TARGET_NOTIFICATIONS_WRITE' as PrivilegesEnum,
        ORDERSUMWRITE: 'ORDER_SUM_WRITE' as PrivilegesEnum,
        BRANCHESREAD: 'BRANCHES_READ' as PrivilegesEnum,
        BRANCHESWRITE: 'BRANCHES_WRITE' as PrivilegesEnum,
        DISPUTESREAD: 'DISPUTES_READ' as PrivilegesEnum,
        DISPUTESWRITE: 'DISPUTES_WRITE' as PrivilegesEnum,
        TAGSREAD: 'TAGS_READ' as PrivilegesEnum,
        TAGSWRITE: 'TAGS_WRITE' as PrivilegesEnum,
        CATEGORYCOMMISSIONREAD: 'CATEGORY_COMMISSION_READ' as PrivilegesEnum,
        CATEGORYCOMMISSIONWRITE: 'CATEGORY_COMMISSION_WRITE' as PrivilegesEnum,
        MERCHANTCOMPANYWRITE: 'MERCHANT_COMPANY_WRITE' as PrivilegesEnum,
        CATEGORYREAD: 'CATEGORY_READ' as PrivilegesEnum,
        CATEGORYWRITE: 'CATEGORY_WRITE' as PrivilegesEnum,
        PAGESEOTAGSREAD: 'PAGE_SEO_TAGS_READ' as PrivilegesEnum,
        PAGESEOTAGSWRITE: 'PAGE_SEO_TAGS_WRITE' as PrivilegesEnum,
        SOURCINGOUTPUTSREAD: 'SOURCING_OUTPUTS_READ' as PrivilegesEnum,
        SOURCINGOUTPUTSWRITE: 'SOURCING_OUTPUTS_WRITE' as PrivilegesEnum,
        SOURCINGINPUTSREAD: 'SOURCING_INPUTS_READ' as PrivilegesEnum,
        SOURCINGINPUTSWRITE: 'SOURCING_INPUTS_WRITE' as PrivilegesEnum
    };
    export type ProfileTypeEnum = 'COMMON_BUYER' | 'COMMON_SELLER' | 'LEGAL_BUYER' | 'LEGAL_SELLER' | 'OPERATOR' | 'COMMON_AGENT' | 'LEGAL_AGENT' | 'UNKNOWN' | 'MODERATOR' | 'TECHNICAL_USER';
    export const ProfileTypeEnum = {
        COMMONBUYER: 'COMMON_BUYER' as ProfileTypeEnum,
        COMMONSELLER: 'COMMON_SELLER' as ProfileTypeEnum,
        LEGALBUYER: 'LEGAL_BUYER' as ProfileTypeEnum,
        LEGALSELLER: 'LEGAL_SELLER' as ProfileTypeEnum,
        OPERATOR: 'OPERATOR' as ProfileTypeEnum,
        COMMONAGENT: 'COMMON_AGENT' as ProfileTypeEnum,
        LEGALAGENT: 'LEGAL_AGENT' as ProfileTypeEnum,
        UNKNOWN: 'UNKNOWN' as ProfileTypeEnum,
        MODERATOR: 'MODERATOR' as ProfileTypeEnum,
        TECHNICALUSER: 'TECHNICAL_USER' as ProfileTypeEnum
    };
}