import { Directionality } from '@angular/cdk/bidi';
import { ChangeDetectorRef, Directive, HostBinding, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PromocodePaginationRequest } from '@portal/models/promocodePaginationRequest';
import { BuilderInfoService } from '../shared';
import LangEnum = PromocodePaginationRequest.LangEnum;

@Directive({
  selector: '[portalDirection]',
})
export class DirectionDirective implements OnInit {
  isRtl: boolean = false;

  @HostBinding('class.rtl')
  get rtl(): boolean {
    return this.isRtl;
  }

  constructor(private readonly builderInfoService: BuilderInfoService) {}

  ngOnInit(): void {
    this.isRtl = this.builderInfoService.interfaceDirection === 'rtl';
  }
}
