import { Direction } from '@angular/cdk/bidi';
import { Injectable } from '@angular/core';
import { PromocodePaginationRequest } from '@portal/models/promocodePaginationRequest';
import { SkuItemShared } from '@portal/models/skuItemShared';
import _assign from 'lodash/assign';
import _defaultsDeep from 'lodash/defaultsDeep';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import {
  AppType,
  BuilderSettings,
  CurrencySettings,
  LanguageLocale,
  LogoConfig,
  PhoneSettings,
  Theme,
} from '../builder.interface';
import { builderEnvironment } from '../environment';
import { Locale } from './builder-info.constants';
import CurrencyEnum = SkuItemShared.CurrencyEnum;
import LangEnum = PromocodePaginationRequest.LangEnum;

@Injectable({ providedIn: 'root' })
export class BuilderInfoService {
  private readonly builderInfo = builderEnvironment;

  private readonly localeCashed: Locale;

  get settings(): BuilderSettings {
    return this.setting.getValue();
  }

  get locale(): Locale {
    return this.localeCashed;
  }

  get currency(): CurrencyEnum {
    return this.setting.getValue().currency;
  }

  get currencySymbol(): string {
    return this.setting.getValue().currencySymbol;
  }

  get availableCurrency(): CurrencyEnum[] {
    const currencies = this.setting.getValue().currencySettings?.map(currency => currency.currency);

    return !!currencies?.length ? currencies : Object.values(CurrencyEnum);
  }

  get currencySettings(): CurrencySettings {
    return this.setting.getValue().currencySettings.find(s => s.active);
  }

  get lang(): LangEnum {
    return this.setting.getValue().langSetting.defaultLang;
  }

  get availableLangs(): LangEnum[] {
    return this.setting.getValue().langSetting.availableLangs;
  }

  get appType(): AppType {
    return this.builderInfo.appType;
  }

  get phoneSettings(): PhoneSettings {
    return this.setting.getValue().phoneSettings.find(s => s.active);
  }

  get theme(): Theme {
    return this.builderInfo.theme || Theme.DEFAULT;
  }

  get sidebarLogoConfig(): LogoConfig {
    return this.builderInfo.assets.logo.sidebar;
  }

  get authPageLogoConfig(): LogoConfig {
    return this.builderInfo.assets.logo.authPage;
  }

  get interfaceDirection(): Direction {
    const localLang: string = localStorage.getItem('language') || this.builderInfo.locale;

    return localLang === LanguageLocale.ar ? 'rtl' : 'ltr';
  }

  private readonly setting = new BehaviorSubject<BuilderSettings>(null);

  constructor() {
    this.localeCashed = this.getLocale();
    this.setting.next(this.builderInfo);
  }

  updateSettings(settings: BuilderSettings) {
    const updated = _assign(settings, _defaultsDeep(settings, builderEnvironment));
    this.setting.next(updated);
  }

  private getLocale(): Locale {
    switch (this.builderInfo.langSetting.defaultLang) {
      case LangEnum.RU:
        return Locale.RU;
      case LangEnum.EN:
        return Locale.EN;
      case LangEnum.FR:
        return Locale.FR;
      case LangEnum.AR:
        return Locale.AR;
      case LangEnum.HI:
        return Locale.HI;
      default:
        return Locale.RU;
    }
  }
}
