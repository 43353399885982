import { OrderShared } from '@portal/models/orderShared';
import { PromocodePaginationRequest } from '@portal/models/promocodePaginationRequest';
import { SkuItemShared } from '@portal/models/skuItemShared';
import CurrencyEnum = SkuItemShared.CurrencyEnum;
import LangEnum = PromocodePaginationRequest.LangEnum;
import StatusEnum = OrderShared.StatusEnum;

export enum AppType {
  Gdesemena = 'Gdesemena',
  Safal = 'Safal',
  Rshb = 'RSHB',
  Stc = 'STC',
}

export enum LanguageLocale {
  en = 'en',
  ru = 'ru',
  fr = 'fr',
  ar = 'ar',
  hi = 'hi',
}

export enum Theme {
  DEFAULT = 'theme-default',
  STC = 'theme-stc',
}

export interface LogoConfig {
  normal: string;
  light?: string;
  small?: string;
}

export interface BuilderEnvironment extends CommonBuilderSettings {
  appType: AppType;
  locale: LanguageLocale;
  assets: {
    logo: {
      sidebar: LogoConfig;
      authPage: LogoConfig;
    };
  };
  recaptcha?: string;
  theme?: Theme;
}

export interface BuilderSettings extends CommonBuilderSettings {
  name?: string;
  id?: number;
}

export interface CommonBuilderSettings {
  langSetting: LangSettings;
  country: string;
  currency: CurrencyEnum;
  currencySymbol: string;
  currencySettings: CurrencySettings[];
  phoneSettings: PhoneSettings[];
  systemLang: LangEnum;
  timeOut: number;
  currencyDisplayType?: CurrencyDisplayType;
  weakPassword?: boolean;
  statusesMap?: Record<StatusEnum, StatusEnum[]>;
  includeDeliveryZoneAddress?: boolean;
}

export interface PhoneSettings {
  phonePrefix: number;
  phoneLength: number;
  active?: boolean;
}

export interface CurrencySettings {
  currency: CurrencyEnum;
  conversionRatio: number;
  active?: boolean;
  format: string;
  currencySymbol: string;
}

export interface LangSettings {
  defaultLang: LangEnum;
  availableLangs: LangEnum[];
}

export enum CurrencyDisplayType {
  CODE = 'code',
  SYMBOL = 'symbol',
  SYMBOL_NARROW = 'symbol-narrow',
}
