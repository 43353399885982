import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { getRuntimeConfig, operatorEnv, RunTimeConfig, setRunTimeConfig } from '@portal/api-endpoints';

getRuntimeConfig()
  .then(async (config: RunTimeConfig) => {
    setRunTimeConfig(config, operatorEnv.endpoints);

    if (operatorEnv.production) {
      enableProdMode();
    }

    await import('./app/app.module').then(m => platformBrowserDynamic().bootstrapModule(m.AppModule));
  })
  .catch(err => {
    console.error(err);
  });
