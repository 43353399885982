/**
 * Item service API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BadgeDetailedShared } from './badgeDetailedShared';
import { CharacteristicWrapperShared } from './characteristicWrapperShared';
import { LicenseShared } from './licenseShared';
import { Margin } from './margin';
import { PackTypeShared } from './packTypeShared';
import { PaymentPlanShared } from './paymentPlanShared';
import { SimpleImage } from './simpleImage';
import { UnitShared } from './unitShared';

export interface SkuItemShared { 
    id?: number;
    license?: LicenseShared;
    paymentPlans?: Array<PaymentPlanShared>;
    skuId?: number;
    categoryId?: number;
    sellerId?: number;
    legalSeller?: boolean;
    vat?: number;
    currency?: SkuItemShared.CurrencyEnum;
    label?: string;
    weight?: number;
    characteristicWrapper?: CharacteristicWrapperShared;
    unitShared?: UnitShared;
    price?: number;
    type?: SkuItemShared.TypeEnum;
    margin?: Margin;
    title?: string;
    skuTitle?: string;
    available?: number;
    minOrderSize?: number;
    maxOrderSize?: number;
    photos?: Array<SimpleImage>;
    packType?: PackTypeShared;
    paymentAttrReferenceIds?: Array<number>;
    badges?: Array<BadgeDetailedShared>;
    category?: string;
}
export namespace SkuItemShared {
    export type CurrencyEnum = 'RUB' | 'USD' | 'GEL' | 'KZT' | 'AZN' | 'INR' | 'SAR' | 'TMT' | 'XOF' | 'ZAR' | 'EGP';
    export const CurrencyEnum = {
        RUB: 'RUB' as CurrencyEnum,
        USD: 'USD' as CurrencyEnum,
        GEL: 'GEL' as CurrencyEnum,
        KZT: 'KZT' as CurrencyEnum,
        AZN: 'AZN' as CurrencyEnum,
        INR: 'INR' as CurrencyEnum,
        SAR: 'SAR' as CurrencyEnum,
        TMT: 'TMT' as CurrencyEnum,
        XOF: 'XOF' as CurrencyEnum,
        ZAR: 'ZAR' as CurrencyEnum,
        EGP: 'EGP' as CurrencyEnum
    };
    export type TypeEnum = 'PHYSICAL' | 'DIGITAL' | 'FINANCIAL_ITEM' | 'TOPUP';
    export const TypeEnum = {
        PHYSICAL: 'PHYSICAL' as TypeEnum,
        DIGITAL: 'DIGITAL' as TypeEnum,
        FINANCIALITEM: 'FINANCIAL_ITEM' as TypeEnum,
        TOPUP: 'TOPUP' as TypeEnum
    };
}