export const authClientContext = {
  token: {
    merchant: '/user-service/api/external/jwtToken',
    operator: '/auth-server/api/external/token',
  },
  seller: {
    legalSellerById: '/user-service/api/external/legalSellers/{id}',
    commonSellerById: '/user-service/api/external/commonSellers/{id}',
  },
};
