import { RoleSaveRequest } from '@portal/models/roleSaveRequest';
import { environment as merchantEnvironment } from './merchant/environment';
import { environment as operatorEnvironment } from './operator/environment';
import ProfileTypeEnum = RoleSaveRequest.ProfileTypeEnum;

const ApiMap = {
  [ProfileTypeEnum.LEGALSELLER]: merchantEnvironment.endpoints,
  [ProfileTypeEnum.OPERATOR]: operatorEnvironment.endpoints,
};

export function getImagesEndpoint(agentType: ProfileTypeEnum): string {
  return ApiMap[agentType].images;
}

export function getApiEndpoint(agentType: ProfileTypeEnum): string {
  return ApiMap[agentType].api;
}
