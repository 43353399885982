export const tagsClientContext = {
  add: '/item-service/api/external/tags',
  delete: '/item-service/api/external/tags/{id}',
  getAll: '/item-service/api/external/tags',
  update: '/item-service/api/external/tags/{id}',
  getById: '/item-service/api/external/tags/{id}',
  sellersTags: '/item-service/api/external/tags/bySellers',
  itemsTags: '/item-service/api/external/tags/byItems',
  categoriesTags: '/item-service/api/external/tags/byCategories',
  image: '/item-service/api/external/tags/{id}/images',
};
