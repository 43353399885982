const api = '/item-service/api/external';

export const skusContext = {
  skus: `${api}/sku`,
  sku: `${api}/sku/{id}`,
  skuBooking: `${api}/sku/{id}/booking`,
  skuTrack: `${api}/sku/{id}/skuTrack`,
  skuTrackImage: `${api}/skuTrack/{id}/image`,
  updateSkuTrack: `${api}/skuTrack/{id}`,
  updateSkuProduct: `${api}/sku/{id}/changeItem`,
  itemSkus: `${api}/items/{id}/sku`,
  skuDiscounts: `${api}/sku/discount`,
  skuDiscountsBySkuId: `${api}/sku/{id}/discount`,
  skuDiscountById: `${api}/sku/discount/{id}`,
  createStockAddress: `${api}/stockAddress`,
  updateStockAddress: `${api}/stockAddress/{id}`,
  deleteStockAddress: `${api}/stockAddress/{id}`,
  getStockAddresses: `${api}/stockAddress`,
  updatePackageType: `${api}/packTypes/{id}`,
  createPackType: `${api}/packTypes`,
  deleteItemsSkus: `${api}/items/sku`,
};
